<template>
    <div class="p-2 d-flex flex-column flex-fill">
        
        <div class="mb-2" v-if="schedule && pet">
            <div class="">
                <h2 class="h2 text-white font-weight-bolder mb-0 pr-1" style="padding-bottom:.5em;border-bottom: 1px solid #FFAD32;width: fit-content;">Confirmation</h2>
            </div> 

            <div class="d-flex mt-2 mb-1 align-items-center justify-content-between" style="gap:10px">
                <div class="h3 font-weight-bolder text-white mb-0">
                    {{pet.pet_name}}
                </div>
                <!-- <div class="h3 font-weight-bolder text-white mb-0 d-flex align-items-center">
                    <i class="bx bx-dollar" style="font-size: 20px;" ></i> {{ training.tr_price }}
                </div> -->
            </div>

            <div>
                <div class="h5 text-white">
                    {{ moment(schedule.ws_date).format('dddd, DD MMMM YYYY') }}, {{ schedule.ws_time }}
                </div> 
            </div>

            <div class="d-flex mt-3 mb-1 align-items-center justify-content-between" style="gap:10px">
                <div class="h3 font-weight-bolder text-white mb-0">
                    Note for Walker
                </div> 
            </div>
            <div class="mt-1" v-for="item in note">
                <div class="text-white" style="gap:10px">
                    {{ moment(item.wn_date).format('dddd, DD MMMM YYYY') }}, {{ item.wn_time }}
                </div>
                <p class="text-white">
                    {{ item.wn_text }}
                </p>
            </div>
                
            <!-- END PAYMENT -->
 
        </div>

        <div class="mt-auto">
            <hr style="border-color: #FFAD32;" />
            <!-- <div class="text-white">Total Payment</div> -->

            <div class="" style="margin-top: .5em;">
                <div class="d-flex align-items-center justify-content-between" style="gap:10px">
                    <div class="h3 font-weight-bolder text-white mb-0 d-flex align-items-center">
                        Pet Walker
                    </div>
                    <div style="cursor: pointer;" @click="bookTraining()" class="ml-auto">
                        <div class="text-white d-flex align-items-center" style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 10px;">
                            <div class="h4 text-white mb-0 ml-auto">Confirm</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import store from '@/store'
import moment from 'moment'

import Cookies from 'universal-cookie'
let cookies = new Cookies()

import Swal from 'sweetalert2'

export default {
    computed: { 
        schedule(){
            return store.state.walker.WALKER_INFO
        },
        pet(){
            return this.schedule?.walker.pet
        }, 
        note(){
            return this.schedule?.walker.note
        }, 
    },
    methods: {
        bookTraining(){
            let payload = new FormData()
            payload.append('inp[ws_id]', this.id)
            payload.append('inp[wpa_id]', this.schedule.walker.wpa_id)
            payload.append('inp[user_id]', cookies.get('token'))

            store.dispatch('walker/BookSchedule', payload).then((res) => {
                this.$router.push(`/walker/invoice/${res.id}`)
            }) 
        }
    },
    mounted(){
        store.dispatch('walker/GetSchedule', this.id)
    },
    data(){
        return {
            moment,
            pet_id: null,
            id: this.$route.params.id
        }
    }
}
</script>

<style>
input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
</style>